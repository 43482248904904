.diary {
        height: 100%;
        display: grid;
        grid-template-columns: 2fr 7fr;
        box-shadow: var(--shadow-elevation-medium);
}

.sidebar {
        background-color: white;
        grid-column: 1;
        grid-row: 1/1;
        list-style: none;
}

.sidebar-item {
        display: block;
        padding: 0.5em;
        text-align: center;
        margin: 0.5em;
}

.content {
        background-color: orange;
        grid-column: 2;
        grid-row: 1/1;
}
