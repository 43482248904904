*,
*::before,
*::after {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
}

:root {
        --abs-background: #fff;
        --background: #eee;
        --foreground: #444;
        --fonts: -apple-system, "Ubuntu", "BlinkMacSystemFont", "Avenir Next",
                "Avenir", "Segoe UI", "Helvetica Neue", "Helvetica", "Roboto",
                "Noto", "Arial", sans-serif;
        /* https://www.joshwcomeau.com/shadow-palette/ */
        --border-radius: 10px;
        --widget-border-radius: var(--border-radius);
        --shadow-color: 0deg 0% 73%;
        --shadow-elevation-low: 0.2px 0.1px 0.2px
                        hsl(var(--shadow-color) / 0.37),
                0.2px 0.2px 0.3px -1.9px hsl(var(--shadow-color) / 0.28),
                0.5px 0.4px 0.6px -3.8px hsl(var(--shadow-color) / 0.2);
        --shadow-elevation-medium: 0.2px 0.1px 0.2px
                        hsl(var(--shadow-color) / 0.38),
                0.3px 0.3px 0.4px -1.3px hsl(var(--shadow-color) / 0.32),
                1px 0.8px 1.2px -2.5px hsl(var(--shadow-color) / 0.25),
                2.6px 2.1px 3.1px -3.8px hsl(var(--shadow-color) / 0.19);
        --shadow-elevation-high: 0.2px 0.1px 0.2px
                        hsl(var(--shadow-color) / 0.41),
                0.5px 0.4px 0.6px -0.6px hsl(var(--shadow-color) / 0.37),
                1px 0.8px 1.2px -1.3px hsl(var(--shadow-color) / 0.33),
                1.9px 1.6px 2.3px -1.9px hsl(var(--shadow-color) / 0.29),
                3.7px 3.1px 4.5px -2.5px hsl(var(--shadow-color) / 0.25),
                6.5px 5.4px 7.9px -3.2px hsl(var(--shadow-color) / 0.21),
                10.7px 8.9px 12.9px -3.8px hsl(var(--shadow-color) / 0.17);
}

html {
        -moz-text-size-adjust: none;
        -webkit-text-size-adjust: none;
        text-size-adjust: none;
        height: 100%;
}

body {
        animation-fill-mode: forwards;
        animation-name: fadeInBody;
        animation-duration: 500ms;
        animation-timing-function: ease-out;
        animation-iteration-count: 1;
        animation-play-state: running;
        background-color: var(--background);
        color: var(--foreground);
        font-size: 16px;
        min-height: 100%;
        height: 100%;
}

#root {
        min-height: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
}

@keyframes fadeInBody {
        from {
                background-color: var(--abs-background);
        }

        to {
                background-color: var(--background);
        }
}

body,
input,
select {
        font-family: var(--fonts);
}

main {
        padding: 1em;
        height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
        padding: 0.5em 0;
}
