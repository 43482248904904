.widget {
        border-radius: var(--widget-border-radius);
        background-color: white;
        color: #333;
        padding: 1em;
        min-height: 100%;
        gap: 0.1em;
        display: grid;
        grid-template-columns: 1fr 1fr;
}

.widgetGrid {
        grid-template-columns: 1fr 1fr;
}

.widgetList {
        grid-template-columns: 1fr;
        gap: 1em;

        .houseContainer {
                justify-content: flex-start;
                gap: 1em;
        }

        .house {
                animation-name: flyFadeInList;
        }
}

.widgetNoAnimate .house {
        animation-name: none;
}

.houseContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        color: inherit;
        text-decoration: none;
        transition: filter 120ms;

        &:hover, &:focus {
                filter: brightness(1.3) contrast(.8);
        }

        &:active {
                filter: brightness(1.2) contrast(.6) saturate(1.5);
        }
}

.house {
        background-color: var(--HouseWidget-bg, orange);
        border-radius: 100px;
        padding: 1.2em;
        aspect-ratio: 1/1;
        animation-fill-mode: both;
        animation-name: flyFadeIn;
        animation-duration: 600ms;
        animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.475);
        animation-iteration-count: 1;
        animation-play-state: running;
}

@for $i from 1 through 8 {
	.houseContainer:nth-child(#{$i}) > .house {
		animation-delay: #{$i * 0.02}s;
                @if $i % 2 == 0 {
                        --flyIn: #{4 + $i * 0.25}em;
                } @else {
                        --flyIn: #{-4 - $i * 0.25}em;
                }
	}
}

@for $i from 1 through 8 {
	.widgetList .houseContainer:nth-child(#{$i}) > .house {
		animation-delay: #{$i * 0.032}s;
	}
}

@keyframes flyFadeIn {
        from {
                transform: translateX(var(--flyIn));
        }

        to {
                transform: translateX(0);
        }
}
@keyframes flyFadeInList {
        from {
                transform: translateX(-4em) translateY(4em);
        }

        to {
                transform: translateX(0) translateY(0);
        }
}
