.main {
        background-color: white;
        box-shadow: var(--shadow-elevation-high);
        display: flex;
        flex-direction: row;
}

.item {
        color: inherit;
        text-decoration: none;
        padding: 1em;
        user-select: none;
        transition: background-color 300ms
                        cubic-bezier(0.175, 0.885, 0.32, 1.475),
                color 275ms cubic-bezier(0.175, 0.885, 0.32, 1.475);
}
/* TODO:  */
.item[data-active="true"] {
        color: white;
        background-color: #641b7c;
}
